<template>
  <div>
    <NuxtLoadingIndicator color="var(--color-green)" :height="2" />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    
  </div>
</template>

<script lang="ts" setup>
const windowStore = useWindow();
const { setIsMobile } = useBreakpoints();

useHead({
  titleTemplate: '%s | Les Maisons du Voyage'
});

onMounted(() => {
  windowStore.changeWidth(window.innerWidth);
  windowStore.changeScroll(window.scrollY);
  window.addEventListener('resize', onResize);
  window.addEventListener('scroll', onScroll);
  onResize();
});

onBeforeUnmount(() => {
  windowStore.changeWidth(window.innerWidth);
  windowStore.changeScroll(window.scrollY);
  window.removeEventListener('resize', onResize);
  window.removeEventListener('scroll', onScroll);
});

function onScroll(): void {
  windowStore.changeScroll(window.scrollY);
}

function onResize(): void {
  const mobileWidth = window
    .getComputedStyle(document.body)
    .getPropertyValue('--breakpoint-value-mobile')
    .replace('px', '');

  setIsMobile(window.innerWidth <= +mobileWidth);
}
</script>
